import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss'],
})
export class ShareModalComponent implements OnInit {

  public isCopy : boolean = false;


  constructor(private modalController : ModalController) { }

  close(){
    this.modalController.dismiss();
  }

  copyMessage(val: string){
    navigator.clipboard.writeText(val).then(() => {
      this.isCopy = true;
      setTimeout(()=> {
        this.isCopy = false;
      }, 2000);

    }, function() {
    });
  }

  ngOnInit() {}

}
