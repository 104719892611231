import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-button-secondary',
  templateUrl: './button-secondary.component.html',
  styleUrls: ['./button-secondary.component.scss'],
})
export class ButtonSecondaryComponent implements OnInit {
  @Input() title : any = 0;

  @Input() lock : any = false;
  @Input() lockLight : any = false;

  @Output('onClick') onClick = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  onClickLocal(){
    this.onClick.emit();
  }

}
