import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {
  @Output('onClick') onClick = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  onClickLocal(){
    this.onClick.emit();
  }

}
