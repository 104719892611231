import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-start-chapter',
  templateUrl: './start-chapter.component.html',
  styleUrls: ['./start-chapter.component.scss'],
})
export class StartChapterComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
