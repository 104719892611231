import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-patience-modal',
  templateUrl: './patience-modal.component.html',
  styleUrls: ['./patience-modal.component.scss'],
})
export class PatienceModalComponent implements OnInit {

  constructor(private modalController : ModalController) { }

  close(){
    this.modalController.dismiss();
  }

  ngOnInit() {}

}
