import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Output('onClick') onClick = new EventEmitter();
  public value = "";

  constructor() { }

  onChangeEvent(event: any){
    this.value = event.target.value;
  }

  onClickLocal() {
    this.onClick.emit(this.value);
  }

  ngOnInit() {}

}
