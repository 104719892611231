import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() title : any = 0;
  @Output('onClick') onClick = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  onClickLocal(){
    this.onClick.emit();
  }

}
