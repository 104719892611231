import { Component, OnInit } from '@angular/core';
import {PatienceModalComponent} from "../../modal/patience-modal/patience-modal.component";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-all-niveau',
  templateUrl: './all-niveau.component.html',
  styleUrls: ['./all-niveau.component.scss'],
})
export class AllNiveauComponent implements OnInit {

  constructor(private modalController : ModalController) { }

  ngOnInit() {}

  async openPatienceModal(){
    const modal = await this.modalController.create({
      component: PatienceModalComponent,
      cssClass: 'shareModal',
    });


    modal.onDidDismiss().then((user) => {

    });

    return await modal.present();
  }

}
