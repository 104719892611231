import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-join-us',
  templateUrl: './join-us.component.html',
  styleUrls: ['./join-us.component.scss'],
})
export class JoinUsComponent implements OnInit {
  @Input() text : any = "";

  constructor() { }

  ngOnInit() {}

}
