import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sound',
  templateUrl: './sound.component.html',
  styleUrls: ['./sound.component.scss'],
})
export class SoundComponent implements OnInit {
  public isPlay : boolean = false;

  constructor() { }

  ngOnInit() {}

  soundBtnClick(){
    this.isPlay = !this.isPlay;
  }

}
